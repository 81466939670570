// ** Logo
import React from 'react'

import logo from "@src/assets/images/logo/logo.png";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <img className="fallback-logo" src={logo} alt="logo" style={{ width:"120px" }} />
      <div className="loading mt-5">
        <div className="effect-1 effects" ></div>
        <div className="effect-2 effects" ></div>
        <div className="effect-3 effects" ></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
